import React, { useEffect, useState } from "react";
const core = require("../../../src/lib/core");
import Header from "./JobPostHeader";
import JobOpening from "./JobOpening";
import Footer from "./JobPostFooter";
import { ScaleLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import NotFound from "./PageNotFound";
import TailorLogo from "../../../src/components/assets/tailorlogo.jpg";

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const fetchJob = async () => {
    setLoading(true);

    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_ORG_JOB_LIST +
          `/${location?.pathname?.split("/")?.at(-1)}`,
        1,
        {},
      );
      setJobList(json.data.content);
      setOrgDetails(json.data.organization);
      console.log("Organisation Details are :", json.data.organization);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, [location]);

  const isTemplateUsed = true;
  const url = String(orgDetails?.domain_url).startsWith("http")
    ? orgDetails?.domain_url
    : `http://${orgDetails?.domain_url}`;

  return (
    <>
      {loading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <ScaleLoader height={36} color="blue" loading={true} />
        </div>
      ) : (
        <>
          {orgDetails.careers_page_integration ? (
            <div
              className={`h-screen flex w-screen flex-col overflow-auto gap-12 `}
            >
              {orgDetails?.template ? (
                <div
                  className="flex justify-center"
                  style={{
                    backgroundColor: orgDetails?.theme_color ?? "#002332", // Dynamic background color based on theme
                  }}
                >
                  <div
                    className={`
          flex-grow py-5 
          ${orgDetails?.template ? "max-w-[80%] pl-10" : "w-full"} 
          flex justify-between items-center 
          border-b border-b-gray-400 
          sm:px-[5%] gap-8 
          h-auto mb-0 
          mt-5
        `}
                  >
                    <div className="flex flex-col ml-4">
                      {/* Logo Section */}
                      <img
                        src={TailorLogo}
                        alt="Logo"
                        className="max-w-[60%] h-auto object-contain"
                      />
                      {/* Organization Name */}
                      <p className="text-white text-lg font-bold mt-4">
                        Tailorbird
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                // If template is not used, render Header component
                <Header orgDetails={orgDetails} />
              )}
              <JobOpening
                isTemplateUsed={orgDetails?.template}
                jobList={jobList}
                orgName={orgDetails?.names}
                orgDetails={orgDetails}
              />
              <Footer />
            </div>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default Jobs;
