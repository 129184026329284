import React from "react";
import Sprouts from "./sprouts.png";

const Footer = () => {
  return (
    <footer className="bg-gray-200 pt-5  pb-4">
      <div className="container mx-auto flex items-center justify-center">
        <p className="text-gray-600 mr-1">Powered By</p>
        <a href="https://www.sproutsai.com/">
          <img
            src={Sprouts}
            alt="Sprouts Logo"
            className="w-28 h-auto ml-2 mb-1 pb-1"
          />
        </a>
      </div>
      <div className="text-center mt-2">
        <p className="text-sm">
          Read our{" "}
          <a
            href="https://www.sproutsai.com/privacy"
            className="text-blue-500 hover:underline"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
